import React, { useState } from "react";
import "./SessionAccordion.scss";
import {
  View,
  Edit,
  DMA,
  DeepDive,
  DGA,
  AddModule,
  ESRSChecklist,
  Copy,
} from "../../../constants/icons/Icons";
import {
  MotifAccordion,
  MotifAccordionTrigger,
  MotifAccordionContent,
  MotifTruncate,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Accordion({
  data,
  isAdmin,
  handleModuleModal,
  handleEditSessionModal,
  handleDeleteSessionModal,
  handleEditModuleModal,
  handleCloneModuleModal,
  ModuleTitleEN,
  projectName,
  sessionData,
  projectId,
}) {
  return (
    <div>
      {data.map((item) => (
        <AccordionItem
          key={item?.id}
          id={item?.id}
          title={item.name}
          frameworkId={item?.framework?.id}
          frameworkTypeId={item?.framework?.frameworkTypeId}
          content={item?.modules}
          isAdmin={isAdmin}
          handleModuleModal={() =>
            handleModuleModal(
              item?.name,
              item?.id,
              item?.framework?.frameworkTypeId
            )
          }
          handleEditSessionModal={handleEditSessionModal}
          handleDeleteSessionModal={handleDeleteSessionModal}
          handleEditModuleModal={handleEditModuleModal}
          handleCloneModuleModal={handleCloneModuleModal}
          ModuleTitleEN={ModuleTitleEN}
          projectName={projectName}
          sessionData={sessionData}
          projectId={projectId}
        />
      ))}
    </div>
  );
}

function AccordionItem({
  id,
  title,
  frameworkId,
  frameworkTypeId,
  content = [],
  isAdmin,
  handleModuleModal,
  handleEditSessionModal,
  handleEditModuleModal,
  handleCloneModuleModal,
  ModuleTitleEN,
  projectName,
  sessionData,
  projectId,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const getCookie = (name) => {
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = regex.exec(document.cookie);
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const handleNavigate = (item) => {
    const routes = {
      DMA: `/dma/${item.id}`,
      DeepDive: `/dd/${item.id}`,
      "DGA (Datapoint Gap Assessment)": `/dga/${item.id}`,
      "ESRS Checklist": `/esrs-checklist/${item.id}`,
    };
    navigate(routes[item.moduleTypeName], {
      state: { token, projectName, sessionData, projectId },
    });
  };
  const toggleAccordion = () => {
    setIsOpen(true);
  };

  return (
    <div className="accordion-item" data-testid="accordion-item">
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
        }}
      >
        <MotifAccordion
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onOpen={toggleAccordion}
          style={{
            flex: "1",
          }}
          variant="editorial"
        >
          <MotifAccordionTrigger onClick={toggleAccordion}>
            <div className="trigger-content">
              {title}
              {isAdmin && (
                <div className="module">
                  <div className="session-top-icons">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditSessionModal(id, title, frameworkId);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
          </MotifAccordionTrigger>
          {isOpen && (
            <MotifAccordionContent>
              <div className="module-cards">
                {content.map((item) => (
                  <div className="module-card" key={item.id}>
                    <div className="module-card-header">
                      <div className="module-type-name">
                        <MotifTruncate className="module-type-truncate">
                          <h4 className="module-type-heading">
                            {item.moduleTypeName}
                          </h4>
                        </MotifTruncate>
                      </div>
                      {item.isClientReady && (
                        <div className="client-ready">
                          <span className="green-dot"></span> Client ready
                        </div>
                      )}
                    </div>

                    <div className="module-icon-container">
                      <div className="module-icon">
                        {item.moduleTypeName === "DMA" && <DMA />}
                        {item.moduleTypeName ===
                          "DGA (Datapoint Gap Assessment)" && <DGA />}
                        {item.moduleTypeName === "DeepDive" && <DeepDive />}
                        {item.moduleTypeName === "ESRS Checklist" && (
                          <ESRSChecklist />
                        )}
                      </div>
                    </div>

                    <div className="module-name">
                      <MotifTruncate className="module-name-truncate">
                        {item.name}
                      </MotifTruncate>
                    </div>

                    <div className="module-card-actions">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleNavigate(item);
                        }}
                      >
                        <View />
                      </IconButton>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditModuleModal(
                            item.id,
                            title,
                            frameworkTypeId
                          );
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCloneModuleModal(item.id);
                        }}
                      >
                        <Copy />
                      </IconButton>
                    </div>
                  </div>
                ))}

                <button
                  className="module-card add-module"
                  type="button"
                  onClick={handleModuleModal}
                >
                  <span className="module-link">
                    <AddModule />
                  </span>
                </button>
              </div>
            </MotifAccordionContent>
          )}
        </MotifAccordion>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  isAdmin: PropTypes.bool,
  data: PropTypes.array.isRequired,
  handleModuleModal: PropTypes.func.isRequired,
  handleEditSessionModal: PropTypes.func.isRequired,
  handleDeleteSessionModal: PropTypes.func.isRequired,
  projectName: PropTypes.string,
  sessionData: PropTypes.array,
  ModuleTitleEN: PropTypes.string.isRequired,
  handleEditModuleModal: PropTypes.func,
  handleCloneModuleModal: PropTypes.func,
  projectId: PropTypes.number.isRequired,
};

AccordionItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  projectName: PropTypes.string,
  frameworkId: PropTypes.number,
  frameworkTypeId: PropTypes.number,
  content: PropTypes.array,
  sessionData: PropTypes.array,
  isAdmin: PropTypes.bool,
  handleModuleModal: PropTypes.func.isRequired,
  handleEditSessionModal: PropTypes.func.isRequired,
  handleEditModuleModal: PropTypes.func,
  handleCloneModuleModal: PropTypes.func,
  ModuleTitleEN: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
};

export default Accordion;
