import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, Typography, Box } from "@mui/material";
import { Dot } from "../../constants/icons/Icons";
import {
  MotifButton,
  MotifFormField,
  MotifToggleSwitch,
  MotifMessage,
} from "@ey-xd/motif-react";
import "./CommentBox.scss";

const CommentBox = ({
  label,
  onSave,
  onCancel,
  cancelButtonLabel,
  saveButtonLabel,
  isMaterial,
  comment,
  setComment,
  isToggled,
  setIsToggled,
  showExtraField,
  dmaResults,
  setDmaResults,
}) => {
  const [commentError, setCommentError] = useState("");
  const [dmaError, setDmaError] = useState("");

  const handleToggle = () => setIsToggled((prev) => !prev);

  const handleSave = () => {
    if (!comment.trim()) {
      setCommentError("Comment must not be empty.");
      return;
    }
    if (comment.length > 500) {
      setCommentError("Comment must not exceed 500 characters.");
      return;
    }
    if (dmaResults.length > 500) {
      setDmaError("DMA results must not exceed 500 characters.");
      return;
    }
    setCommentError("");
    setDmaError("");
    onSave && onSave(comment, isToggled, dmaResults);
  };

  const handleCancel = () => {
    setComment("");
    setCommentError("");
    setDmaResults("");
    setDmaError("");
    onCancel && onCancel();
  };

  return (
    <Box className="comment-toggle-container">
      <Box
        className="comment-toggle-header"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          className="DGA-Label"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Dot style={{ marginTop: "2%" }} /> &nbsp;{label}
        </Typography>
      </Box>
      <Box className="comment-toggle-right">
        <MotifFormField className="dga-toggle">
          <MotifToggleSwitch
            onChange={handleToggle}
            id="exampleToggle"
            hideLabels
            checked={isToggled}
          />
        </MotifFormField>
        <MotifMessage className="material-text" data-testid="human-rights">
          {isMaterial}
        </MotifMessage>
      </Box>

      {showExtraField && (
        <TextField
          label="DMA results"
          multiline
          variant="outlined"
          rows={10}
          value={dmaResults}
          onChange={(e) => {
            if (e.target.value.length <= 3000) {
              setDmaResults(e.target.value);
              setDmaError("");
            } else {
              setDmaError("DMA results must not exceed 3000 characters.");
            }
          }}
          fullWidth
          sx={{ marginTop: 2 }}
          error={!!dmaError}
          helperText={dmaError}
        />
      )}
      <TextField
        label="Comment"
        multiline
        rows={showExtraField ? 12 : 25}
        variant="outlined"
        value={comment}
        onChange={(e) => {
          if (e.target.value.length <= 3000) {
            setComment(e.target.value);
            setCommentError("");
          } else {
            setCommentError("Comment must not exceed 3000 characters.");
          }
        }}
        fullWidth
        error={!!commentError}
        helperText={commentError}
      />

      <Box className="comment-toggle-actions">
        <MotifButton onClick={handleCancel} className="modal-cancel-button">
          {cancelButtonLabel}
        </MotifButton>
        <MotifButton onClick={handleSave} className="modal-done-button">
          {saveButtonLabel}
        </MotifButton>
      </Box>
    </Box>
  );
};

CommentBox.propTypes = {
  label: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  cancelButtonLabel: PropTypes.string,
  saveButtonLabel: PropTypes.string,
  isMaterial: PropTypes.string,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  isToggled: PropTypes.bool.isRequired,
  setIsToggled: PropTypes.func.isRequired,
  showExtraField: PropTypes.bool,
  dmaResults: PropTypes.string,
  setDmaResults: PropTypes.func,
};

export default CommentBox;
