import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from 'prop-types';
import { Box, Grid, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  MotifProgressLoader,
  MotifLabel,
  MotifSelect,
  MotifOption,
  MotifFormField,
  MotifMessage,
} from "@ey-xd/motif-react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IROAssessmentSubheader from "../../../components/iroAssessmentSubheader/IROAssessmentSubheader";
import {
  getDGADataPointQuery,
  getMaturityLevels,
  upsertDataPoint,
  getDataPointsByDRId,
  clearError,
} from "../../../features/slices/DGAContext";
import { fetchClientsDetails } from "../../../features/slices/ProjectScreens";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DgaAssessmentModal from "../../../components/modals/dmaModals/DgaAssessmentModals";
import MappingForm from "../../../components/forms/MappingForm";
import ErrorModal from "../../../components/modals/error/errorModal";
import AssessmentCard from "./AssessmentCard";
import ArModal from "../../../components/modals/dmaModals/ArModal";
import { handleError as handleLogoutError } from "../../../utils/handleError";

const AssessmentDga = ({ assessmentDgaId }) => {
  const dispatch = useDispatch();
  const { moduleId: moduleIdParam } = useParams();
  const moduleId = parseInt(moduleIdParam, 10);
  const location = useLocation();
  const navigate = useNavigate();
  const projectId = location.state?.projectId;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [maturityLevels, setMaturityLevels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEsrs, setSelectedEsrs] = useState("");
  const [selectedDr, setSelectedDr] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({});
  const [raciDropdownData, setRaciDropdownData] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const [isARModalOpen, setIsARModalOpen] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const [dataPointsByDRId, setDataPointsByDRId] = useState([]);
  const [isDrModalOpen, setIsDrModalOpen] = useState(false);
  const [question, setQuestion] = useState("");

  const itemsPerPage = 5;
  const token = useSelector((state) => state.user.jwtToken) ||
    document.cookie.match(new RegExp(`(^| )authToken=([^;]+)`))?.[2] ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();

  const { isError, isGenericError, errorMessage } = useSelector(
    (state) => state.dgaSustainabilityMatters
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [dataResult, maturityLevelsResult, clientsDetailsResult] =
        await Promise.all([
          dispatch(getDGADataPointQuery({ moduleId, token })).unwrap(),
          dispatch(getMaturityLevels({ token })).unwrap(),
          dispatch(fetchClientsDetails({ projectId, token })).unwrap(),
        ]);
      setData(dataResult?.data);
      setMaturityLevels(maturityLevelsResult?.data);
      setRaciDropdownData(
        clientsDetailsResult?.data.map((item) => ({
          ...item,
          typeName: item.clientOrganisationType?.name || "Unknown",
        })) || []
      );

      const dataPointsByDRIdResult = await dispatch(
        getDataPointsByDRId({ moduleId, drId: assessmentDgaId || 1, token })
      ).unwrap();
      setDataPointsByDRId(dataPointsByDRIdResult.data[0]);
      if (dataResult?.data.length > 0) {
        setSelectedEsrs(
          dataPointsByDRIdResult.data[0].esrs || dataResult.data[0].esrs
        );
        setSelectedDr(
          dataPointsByDRIdResult.data[0].dr || dataResult.data[0].dr
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, moduleId, token, projectId, assessmentDgaId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const uniqueEsrs = useMemo(() => {
    return [...new Set(data?.map((item) => item.esrs))];
  }, [data]);

  const uniqueDr = useMemo(() => {
    return selectedEsrs
      ? [
          ...new Set(
            data
              ?.filter((item) => item.esrs === selectedEsrs)
              .map((item) => item.dr)
          ),
        ]
      : [];
  }, [data, selectedEsrs]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedEsrs, selectedDr]);

  const filteredData = useMemo(() => {
    return data?.filter((item) => {
      return (
        (selectedEsrs ? item.esrs === selectedEsrs : true) &&
        (selectedDr ? item.dr === selectedDr : true)
      );
    });
  }, [data, selectedEsrs, selectedDr]);

  const paginatedData = useMemo(() => {
    return filteredData?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [filteredData, currentPage, itemsPerPage]);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleEditClick = async (item) => {
    setSelectedItem(item);
    setIsLoading(true);

    try {
      const response = await dispatch(
        getDGADataPointQuery({ moduleId, token })
      ).unwrap();
      const selectedData = response.data.find(
        (dataItem) => dataItem.id === item.id
      );

      const newFormData = {
        maturityLevelId: selectedData?.maturityLevelId?.toString() || "",
        descriptionOfGap: selectedData?.descriptionOfGap || "",
        documentation: selectedData?.documentation || "",
        sourceOfData: selectedData?.sourceOfData || "",
        completenessOfData: selectedData?.completenessOfData || "",
      };
      setFormData(newFormData);
      setInitialData({
        responsible: selectedData.raciResponsibleId?.toString() || "",
        accountable: selectedData.raciAccountableId?.toString() || "",
        consulted: selectedData.raciConsultedId?.toString() || "",
        informed: selectedData.raciInformedId?.toString() || "",
        others: selectedData.raciOthersId?.toString() || "",
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching data point:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatData = (data) => {
    let formattedData = "";

    try {
      const parsedData = JSON.parse(data);
      parsedData.forEach((item) => {
        formattedData += `
          ${item.Content.replace(/\n/g, "<br/>")}</p>
        `;
      });
    } catch (error) {
      console.error("Error parsing application requirements:", error);
    }
    return formattedData;
  };

  const handleARClick = (item) => {
    setSelectedItem(item);
    setIsARModalOpen(true);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    if (!selectedItem) return;

    const payload = {
      moduleId: moduleId,
      dPstaticId: selectedItem.id,
      maturityLevelId: parseInt(formData.maturityLevelId, 10),
      materiality: true,
      descriptionOfGap: formData.descriptionOfGap || "",
      documentation: formData.documentation || "",
      sourceOfData: formData.sourceOfData || "",
      completenessOfData: formData.completenessOfData || "",
      comment: "Comment",
      raciResponsibleId: parseInt(initialData.responsible, 10) || null,
      raciAccountableId: parseInt(initialData.accountable, 10) || null,
      raciConsultedId: parseInt(initialData.consulted, 10) || null,
      raciInformedId: parseInt(initialData.informed, 10) || null,
      raciOthersId: parseInt(initialData.others, 10) || null,
    };

    try {
      setIsLoading(true);
      await dispatch(
        upsertDataPoint({ datapointData: payload, token })
      ).unwrap();
      setIsModalOpen(false);
      const updatedData = await dispatch(
        getDGADataPointQuery({ moduleId, token })
      ).unwrap();
      setData(updatedData?.data);
    } catch (error) {
      console.error("Error saving data point:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRaciChange = (updatedData) => {
    setInitialData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };

  const handleDisclosureRequirementClick = () => {
    setIsDrModalOpen(true);
  };

  const tabs = useMemo(
    () => [
      {
        label: "GAP",
        content: (
          <div>
            <div style={{ marginBottom: "20px" }}>
              <MotifMessage id="form-title">{t("MaturityLevel")}</MotifMessage>
              <MotifFormField>
                <MotifSelect
                  className="iro-textField"
                  ariaLabelledBy="select-dma-select-label"
                  visibleOptions={3}
                  onChange={(e) => handleChange("maturityLevelId", e)}
                  value={formData.maturityLevelId || ""}
                >
                  {maturityLevels.map((item) => (
                    <MotifOption key={item.id} value={item.id.toString()}>
                      {item.name}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
            <div>
              <MotifLabel className="iro-label">Description of GAP</MotifLabel>
              <TextField
                placeholder=""
                multiline
                fullWidth
                rows={4}
                value={formData.descriptionOfGap || ""}
                onChange={(e) =>
                  handleChange("descriptionOfGap", e.target.value)
                }
              />
            </div>
          </div>
        ),
      },
      {
        label: "Documentation",
        content: (
          <div>
            <MotifLabel className="iro-label">Description</MotifLabel>
            <TextField
              placeholder=""
              multiline
              fullWidth
              rows={4}
              value={formData.documentation || ""}
              onChange={(e) => handleChange("documentation", e.target.value)}
            />
          </div>
        ),
      },
      {
        label: "Source of data",
        content: (
          <div>
            <MotifLabel className="iro-label">Description</MotifLabel>
            <TextField
              placeholder=""
              multiline
              fullWidth
              rows={4}
              value={formData.sourceOfData || ""}
              onChange={(e) => handleChange("sourceOfData", e.target.value)}
            />
          </div>
        ),
      },
      {
        label: "Completeness",
        content: (
          <div>
            <MotifLabel className="iro-label">Description</MotifLabel>
            <TextField
              placeholder=""
              multiline
              fullWidth
              rows={4}
              value={formData.completenessOfData || ""}
              onChange={(e) =>
                handleChange("completenessOfData", e.target.value)
              }
            />
          </div>
        ),
      },
      {
        label: "RACI",
        content: (
          <MappingForm
            responsibleLabel={t("Responsible")}
            accountableLabel={t("Accountable")}
            consultedLabel={t("Consulted")}
            informedLabel={t("Informed")}
            othersLabel={t("Others")}
            selectResponsilbleLabel={t("SelectResponsible")}
            selectAccountableLabel={t("SelectAccountable")}
            selectConsultedLabel={t("SelectConsulted")}
            selectInformedLabel={t("SelectInformed")}
            selectOthersLabel={t("SelectOthers")}
            RACIMapping={t("RACIMapping")}
            raciDropdownData={raciDropdownData}
            initialData={initialData}
            onChange={handleRaciChange}
          />
        ),
      },
    ],
    [formData, maturityLevels, raciDropdownData, initialData, t]
  );

  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  useEffect(() => {
    if (selectedDr) {
      const selectedDataPoint = data.find((item) => item.dr === selectedDr);
      if (selectedDataPoint) {
        setQuestion(selectedDataPoint.question);
      }
    }
  }, [selectedDr, data]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <ErrorModal
        setName={t("Error")}
        labelText={localErrorMessage}
        handleButtonClick={
          isGenericError
            ? () => handleLogoutError(dispatch, navigate)
            : handleCancelErrorModal
        }
        deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
      />
    );
  }

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <Box sx={{ margin: "auto", padding: "10px" }}>
        <IROAssessmentSubheader
          header={dataPointsByDRId.dresrs}
          subHeader={dataPointsByDRId.name}
          previousButtonLabel="Disclosure requirement"
          SelectESRS="ESRS"
          processText={question}
          SelectDisclosure="Disclosure requirement"
          EsrsArray={uniqueEsrs}
          GeneralDisclosureArray={uniqueDr}
          esrs={selectedEsrs}
          generalDisclosure={selectedDr}
          setEsrs={setSelectedEsrs}
          setGeneralDisclosure={setSelectedDr}
          handlePreviousClick={handleDisclosureRequirementClick}
        />
      </Box>
      <Box sx={{ maxWidth: "1376px", margin: "auto", padding: "10px" }}>
        <Grid container spacing={3}>
          {paginatedData?.map((item) => (
            <AssessmentCard
              key={item.id}
              item={item}
              onEdit={handleEditClick}
              onARClick={handleARClick}
              isDisabled={
                item.applicationRequirements === null ||
                item.applicationRequirements === ""
              }
            />
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Button
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            sx={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleNextPage}
            disabled={paginatedData?.length < itemsPerPage}
            sx={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
            }}
          >
            Next
          </Button>
        </Box>
      </Box>

      {isModalOpen && (
        <DgaAssessmentModal
          onClose={() => setIsModalOpen(false)}
          modalTitle="Assessment"
          tabs={tabs}
          saveButtonLabel="Save"
          cancelButtonLabel="Cancel"
          disable={false}
          onSave={handleSave}
        />
      )}
      {isDrModalOpen && (
        <ArModal
          onClose={() => setIsDrModalOpen(false)}
          title="Disclosure Requirement"
          description={dataPointsByDRId?.drText}
          cancelButtonLabel="Close"
          isDisabled={true}
        />
      )}
      {isARModalOpen && (
        <ArModal
          onClose={() => setIsARModalOpen(false)}
          title="Application requirements"
          description={formatData(selectedItem?.applicationRequirements)}
          cancelButtonLabel="Close"
          isDisabled={true}
        />
      )}
    </>
  );
};

AssessmentDga.propTypes = {
  assessmentDgaId: PropTypes.number.isRequired,
};

export default AssessmentDga;