import React, { useReducer, useEffect, useCallback, useState } from "react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import CardDMS from "../../../../components/cardDMS/CardDMS";
import { Edit } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box } from "@mui/material";
import SourceValue from "../../../../components/modals/dmaModals/SourcesValue";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchValueChainTypes,
  fetchValueChain,
  createNewValueChain,
  updateValueChain,
  deleteValueChain,
  clearError,
} from "../../../../features/slices/DMAValueChain";
import { MotifProgressLoader, MotifTruncate } from "@ey-xd/motif-react";
import { useParams, useNavigate } from "react-router-dom";
import TopBar from "../Topbar";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import Pagination from "../../../../components/paginationForCards/Pagination";
import ErrorModal from "../../../../components/modals/error/errorModal";
import "./ValueChain.scss";
import { useTranslation } from "react-i18next";
import { handleError as handleLogoutError } from "../../../../utils/handleError";

const initialState = {
  isValueChainTableView: true,
  isValueChainModalOpen: false,
  valueChainModalMode: "add",
  valueChainModalTitle: "",
  currentValueChainPage: 1,
  valueChainSearchQuery: "",
  valueChainSelectedRow: null,
  valueChainTypes: [],
  isValueChainDeleteModalOpen: false,
  updateValueChainTrigger: 0,
  valueChaindata: [],
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_IS_VALUE_CHAIN_TABLE_VIEW":
      return { ...state, isValueChainTableView: action.payload };
    case "SET_IS_VALUE_CHAIN_MODAL_OPEN":
      return { ...state, isValueChainModalOpen: action.payload };
    case "SET_VALUE_CHAIN_MODAL_MODE":
      return { ...state, valueChainModalMode: action.payload };
    case "SET_VALUE_CHAIN_MODAL_TITLE":
      return { ...state, valueChainModalTitle: action.payload };
    case "SET_CURRENT_VALUE_CHAIN_PAGE":
      return { ...state, currentValueChainPage: action.payload };
    case "SET_VALUE_CHAIN_SEARCH_QUERY":
      return { ...state, valueChainSearchQuery: action.payload };
    case "SET_VALUE_CHAIN_SELECTED_ROW":
      return { ...state, valueChainSelectedRow: action.payload };
    case "SET_VALUE_CHAIN_TYPES":
      return { ...state, valueChainTypes: action.payload };
    case "SET_IS_VALUE_CHAIN_DELETE_MODAL_OPEN":
      return { ...state, isValueChainDeleteModalOpen: action.payload };
    case "SET_UPDATE_VALUE_CHAIN_TRIGGER":
      return { ...state, updateValueChainTrigger: action.payload };
    case "SET_VALUE_CHAIN_DATA":
      return { ...state, valueChaindata: action.payload };
    default:
      return state;
  }
}

const ValueChain = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { isLoading, isError, errorMessage, isGenericError } = useSelector(
    (state) => state.dmaValueChain
  );
  const { t } = useTranslation();
  const fetchData = useCallback(async () => {
    try {
      const [valueChainResponse, typesResponse] = await Promise.all([
        reduxDispatch(fetchValueChain({ moduleId, token })).unwrap(),
        reduxDispatch(fetchValueChainTypes(token)).unwrap(),
      ]);
      if (Array.isArray(valueChainResponse.data)) {
        const valueChainData = valueChainResponse.data.map((item) => ({
          ...item,
          typeName: item.valueChainType?.name || "Unknown",
        }));
        dispatch({ type: "SET_VALUE_CHAIN_DATA", payload: valueChainData });
      } else {
        dispatch({ type: "SET_VALUE_CHAIN_DATA", payload: [] });
      }
      if (typesResponse.success) {
        dispatch({
          type: "SET_VALUE_CHAIN_TYPES",
          payload: typesResponse.data,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [reduxDispatch, moduleId, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData, state.updateValueChainTrigger]);

  const handleValueChainEdit = useCallback((row) => {
    dispatch({ type: "SET_VALUE_CHAIN_SELECTED_ROW", payload: row });
    dispatch({ type: "SET_VALUE_CHAIN_MODAL_MODE", payload: "edit" });
    dispatch({
      type: "SET_VALUE_CHAIN_MODAL_TITLE",
      payload: "Edit value chain",
    });
    dispatch({ type: "SET_IS_VALUE_CHAIN_MODAL_OPEN", payload: true });
  }, []);

  const handleValueChainDeleteOpenModal = useCallback(() => {
    dispatch({ type: "SET_IS_VALUE_CHAIN_DELETE_MODAL_OPEN", payload: true });
  }, []);

  const handleValueChainDeleteCloseModal = useCallback(() => {
    dispatch({ type: "SET_IS_VALUE_CHAIN_DELETE_MODAL_OPEN", payload: false });
  }, []);

  const handleValueChainDelete = useCallback(async () => {
    try {
      const response = await reduxDispatch(
        deleteValueChain({
          valueChainId: state.valueChainSelectedRow.id,
          moduleId,
          token,
        })
      )
        .unwrap()
        .then(() => {
          dispatch({
            type: "SET_UPDATE_VALUE_CHAIN_TRIGGER",
            payload: state.updateValueChainTrigger + 1,
          });
          handleValueChainDeleteCloseModal();
          dispatch({ type: "SET_IS_VALUE_CHAIN_MODAL_OPEN", payload: false });
        });
      if (!response.error) {
        dispatch({
          type: "SET_VALUE_CHAIN_DATA",
          payload: state.valueChaindata.filter(
            (valueChain) => valueChain.id !== state.valueChainSelectedRow.id
          ),
        });
      } else {
        console.error("Error deleting Value Chain:", response.error);
      }
    } catch (error) {
      console.error("Error deleting Value Chain:", error);
    }
  }, [
    reduxDispatch,
    state.valueChainSelectedRow,
    state.updateValueChainTrigger,
    state.valueChaindata,
    moduleId,
    token,
    handleValueChainDeleteCloseModal,
  ]);

  const handleValueChainClick = useCallback(
    async (valueChainData) => {
      try {
        let response;
        if (state.valueChainModalMode === "add") {
          response = await reduxDispatch(
            createNewValueChain({ moduleId, valueChainData, token })
          );
          if (response.payload) {
            dispatch({
              type: "SET_VALUE_CHAIN_DATA",
              payload: [...state.valueChaindata, response.payload],
            });
            dispatch({
              type: "SET_UPDATE_VALUE_CHAIN_TRIGGER",
              payload: state.updateValueChainTrigger + 1,
            });
          } else {
            console.error("Error creating Value Chain:", response.error);
          }
        } else if (
          state.valueChainModalMode === "edit" &&
          state.valueChainSelectedRow
        ) {
          response = await reduxDispatch(
            updateValueChain({
              valueChainId: state.valueChainSelectedRow.id,
              moduleId,
              valueChainData,
              token,
            })
          );
          if (response.payload) {
            dispatch({
              type: "SET_VALUE_CHAIN_DATA",
              payload: state.valueChaindata.map((valueChain) =>
                valueChain.id === response.payload.id
                  ? response.payload
                  : valueChain
              ),
            });
            dispatch({
              type: "SET_UPDATE_VALUE_CHAIN_TRIGGER",
              payload: state.updateValueChainTrigger + 1,
            });
          } else {
            console.error("Error updating Value Chain:", response.error);
          }
        }
        dispatch({ type: "SET_IS_VALUE_CHAIN_MODAL_OPEN", payload: false });
      } catch (error) {
        console.error("Error creating/updating Value Chain:", error);
      }
    },
    [
      reduxDispatch,
      state.valueChainModalMode,
      state.valueChainSelectedRow,
      state.valueChaindata,
      state.updateValueChainTrigger,
      moduleId,
      token,
    ]
  );

  const renderValueChainTableCell = useCallback(
    (colName, value, row, index) => {
      if (colName === "Action") {
        return (
          <TableCell
            key="actions"
            sx={{ textAlign: "right", width: "100px" }}
          >
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={() => handleValueChainEdit(row)}>
                <Edit />
              </IconButton>
            </Box>
          </TableCell>
        );
      }
      if (colName === "valueChainType") {
        return <TableCell key={index}>{row.valueChainType.name}</TableCell>;
      }
      if (colName === "description") {
        return (
          <TableCell key={index}>
            <MotifTruncate expanded={true}>{value}</MotifTruncate>
          </TableCell>
        );
      }
      return <TableCell key={index}>{value}</TableCell>;
    },
    [handleValueChainEdit]
  );

  const generateValueChainRowKey = useCallback((row) => row.id, []);

  const handleValueChainOpenModal = useCallback((mode) => {
    dispatch({ type: "SET_VALUE_CHAIN_MODAL_MODE", payload: mode });
    if (mode === "add") {
      dispatch({
        type: "SET_VALUE_CHAIN_MODAL_TITLE",
        payload: "New value chain",
      });
    } else if (mode === "edit") {
      dispatch({
        type: "SET_VALUE_CHAIN_MODAL_TITLE",
        payload: "Edit value chain",
      });
    }
    dispatch({ type: "SET_IS_VALUE_CHAIN_MODAL_OPEN", payload: true });
  }, []);

  const handleValueChainCloseModal = useCallback(() => {
    dispatch({ type: "SET_IS_VALUE_CHAIN_MODAL_OPEN", payload: false });
  }, []);

  const handleValueChainPageChange = useCallback((page) => {
    dispatch({ type: "SET_CURRENT_VALUE_CHAIN_PAGE", payload: page });
  }, []);

  const handleValueChainSearch = useCallback((event) => {
    dispatch({ type: "SET_VALUE_CHAIN_SEARCH_QUERY", payload: String(event) });
  }, []);

  const columnsValueChain = [
    { colName: "name", label: t("Name"), showSorting: true },
    {
      colName: "typeName",
      label: "Type",
      showSorting: true,
    },
    {
      colName: "description",
      label: t("TabDescription"),
      showSorting: true,
      width: "75em",
    },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    reduxDispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  const getValueChainFilteredData = () => {
    return Array.isArray(state.valueChaindata)
      ? state.valueChaindata.filter((item) =>
          item.name
            ?.toLowerCase()
            .includes(state.valueChainSearchQuery.toLowerCase())
        )
      : [];
  };

  const paginatedData = getValueChainFilteredData().slice(
    (state.currentValueChainPage - 1) * itemsPerPage,
    state.currentValueChainPage * itemsPerPage
  );

  const totalPages = Math.ceil(
    getValueChainFilteredData().length / itemsPerPage
  );

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleValueChainOpenModal("add")}
        onChangeSearchInput={handleValueChainSearch}
      />
      <TopBar
        isTableView={state.isValueChainTableView}
        totalResults={state.valueChaindata.length}
        filteredResults={getValueChainFilteredData().length}
        onTableViewClick={() =>
          dispatch({ type: "SET_IS_VALUE_CHAIN_TABLE_VIEW", payload: true })
        }
        onCardViewClick={() =>
          dispatch({ type: "SET_IS_VALUE_CHAIN_TABLE_VIEW", payload: false })
        }
      />
      <div className="value-chain-card-table-switch">
        {state.isValueChainTableView ? (
          <ClientTable
            columns={columnsValueChain}
            data={getValueChainFilteredData()}
            itemsPerPage={5}
            renderTableCell={(colName, value, row, index) => {
              return renderValueChainTableCell(colName, value, row, index);
            }}
            generateRowKey={generateValueChainRowKey}
          />
        ) : (
          <div className="card-container">
            {paginatedData.map((item) => (
              <CardDMS
                key={item.id}
                header={item.name}
                pageName="ValueChain"
                stakeHolderType={item.valueChainType.name}
                descriptionHeaderText={item.description}
                handleEdit={() => handleValueChainEdit(item)}
              />
            ))}
          </div>
        )}
        {!state.isValueChainTableView && (
          <Pagination
            totalPages={totalPages}
            currentPage={state.currentValueChainPage}
            onPageChange={handleValueChainPageChange}
          />
        )}
      </div>
      {state.isValueChainModalOpen && (
        <SourceValue
          onClose={handleValueChainCloseModal}
          setName={state.valueChainModalTitle}
          handleStatusChange={() => {}}
          handleSourceValueClick={handleValueChainClick}
          modalMode={state.valueChainModalMode}
          NameLabel={t("Name")}
          TypeLabel={t("Type")}
          InputName={t("EnterName")}
          SelectType={t("SelectType")}
          DescriptionLabel={t("Description")}
          cancelButtonLabel={t("Cancel")}
          saveButtonLabel={t("AddToList")}
          updateButtonLabel={t("Update")}
          selectedRow={state.valueChainSelectedRow}
          isDisabled={isLoading}
          pageName="Value Chain"
          types={state.valueChainTypes}
          onDeleteClick={handleValueChainDeleteOpenModal}
          DeleteLabel={t("DeleteLabel")}
        />
      )}
      {state.isValueChainDeleteModalOpen && (
        <DeleteModal
          isOpen={state.isValueChainDeleteModalOpen}
          onClose={() =>
            dispatch({
              type: "SET_IS_VALUE_CHAIN_DELETE_MODAL_OPEN",
              payload: false,
            })
          }
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={() =>
            dispatch({
              type: "SET_IS_VALUE_CHAIN_DELETE_MODAL_OPEN",
              payload: false,
            })
          }
          handleDeleteClick={handleValueChainDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </div>
  );
};

export default ValueChain;
