import React, { useState, useCallback } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifCheckbox,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ClientTable from "../../components/clientTable/ClientTable";
import { TableCell } from "@mui/material";

const TemplateModal = ({
  title,
  onClose,
  onConfirm,
  columns,
  data,
  itemsPerPage,
  generateRowKey,
  showSelect = false,
  selectableOptions = [],
  confirmButtonText,
  cancelButtonText,
}) => {
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedRows, setSelectedRows] = useState({});

  const handleCheckboxChange = useCallback((projectId) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected[projectId]) {
        return {};
      }
      return { [projectId]: true };
    });
  }, []);

  const handleStatusChange = useCallback((event) => {
    setSelectedStatus(event);
  }, []);

  const handleConfirmClick = useCallback(() => {
    const selectedProjects = Object.keys(selectedRows).filter(
      (key) => selectedRows[key]
    );

    if (onConfirm) onConfirm(selectedStatus, selectedProjects);
  }, [selectedRows, selectedStatus, onConfirm]);

  const renderTableCell = useCallback(
    (colName, value, row, index) => {
      const rowId = row.id;
      const isAnyCheckboxSelected = Object.values(selectedRows).some((isSelected) => isSelected);
      if (colName === "projectName") {
        return (
          <TableCell key={index} style={{ textAlign: "left" }}>
            <MotifCheckbox
              id={`checkbox-${rowId}`}
              name={`checkbox-${rowId}`}
              className="template-checkbox"
              value="Active"
              checked={!!selectedRows[row.id]}
              onChange={() => handleCheckboxChange(row.id)}
              disabled={isAnyCheckboxSelected && !selectedRows[row.id]}
            >
              {value}
            </MotifCheckbox>
          </TableCell>
        );
      }
      return (
        <TableCell
          style={{ textAlign: "right", paddingRight: "9.85%" }}
          key={index}
        >
          {value}
        </TableCell>
      );
    },
    [selectedRows, handleCheckboxChange]
  );

  const isConfirmDisabled = Object.values(selectedRows).every(
    (isSelected) => !isSelected
  );

  return (
    <div className="modal-overlay">
      <div className="modal-template">
        <div className="modal-header">
          <h2>{title}</h2>
        </div>
        <div className="modal-template-body">
          <ClientTable
            columns={columns}
            data={data}
            itemsPerPage={itemsPerPage}
            renderTableCell={renderTableCell}
            generateRowKey={generateRowKey}
          />

          {showSelect && (
            <div className="form-template-group">
              <MotifLabel id="select-label">
                {t("Select from the list")}
              </MotifLabel>
              <MotifFormField>
                <MotifSelect
                  className="change-status-modal"
                  onChange={handleStatusChange}
                  value={selectedStatus}
                >
                  {selectableOptions.map((option, index) => (
                    <MotifOption key={index} value={option.value}>
                      {option.label}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="modal-cancel-button">
            {cancelButtonText}
          </MotifButton>
          <MotifButton
            onClick={handleConfirmClick}
            className="modal-done-button"
            disabled={isConfirmDisabled}
          >
            {confirmButtonText}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

TemplateModal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number,
  generateRowKey: PropTypes.func.isRequired,
  showSelect: PropTypes.bool,
  selectableOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

export default TemplateModal;
