import React, {
  useEffect,
  useReducer,
  useMemo,
  useCallback,
  useState,
} from "react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import CardDMS from "../../../../components/cardDMS/CardDMS";
import { Edit } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import SourceValue from "../../../../components/modals/dmaModals/SourcesValue";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAps,
  fetchApsTypes,
  createAps,
  updateAps,
  deleteAps,
  clearError,
} from "../../../../features/slices/DMAAps";
import { MotifProgressLoader, MotifTruncate } from "@ey-xd/motif-react";
import Pagination from "../../../../components/paginationForCards/Pagination";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import TopBar from "../Topbar";
import ErrorModal from "../../../../components/modals/error/errorModal";
import "./APS.scss";
import { handleError as handleLogoutError } from "../../../../utils/handleError";
import { useTranslation } from "react-i18next";
const initialState = {
  isApsTableView: true,
  isApsModalOpen: false,
  modalApsMode: "add",
  modalApsTitle: "",
  selectedApsRow: null,
  currentApsPage: 1,
  searchApsQuery: "",
  isApsDeleteModalOpen: false,
  apsToDelete: null,
  types: [],
  apsData: [],
};

const apsReducer = (state, action) => {
  switch (action.type) {
    case "SET_VIEW":
      return { ...state, isApsTableView: action.payload };
    case "SET_MODAL":
      return {
        ...state,
        isApsModalOpen: action.payload.isOpen,
        modalApsMode: action.payload.mode,
        modalApsTitle: action.payload.title,
        selectedApsRow: action.payload.row,
      };
    case "SET_PAGE":
      return { ...state, currentApsPage: action.payload };
    case "SET_SEARCH_QUERY":
      return { ...state, searchApsQuery: action.payload };
    case "SET_DELETE_MODAL":
      return {
        ...state,
        isApsDeleteModalOpen: action.payload.isOpen,
        apsToDelete: action.payload.row,
      };
    case "SET_TYPES":
      return { ...state, types: action.payload };
    case "SET_APS_DATA":
      return { ...state, apsData: action.payload };
    case "SET_APS_TO_DELETE":
      return { ...state, apsToDelete: action.payload };
    case "SET_IS_APS_DELETE_MODAL_OPEN":
      return { ...state, isApsDeleteModalOpen: action.payload };
    default:
      return state;
  }
};

const Aps = () => {
  const [state, dispatchState] = useReducer(apsReducer, initialState);
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();
  const { isLoading, isError, errorMessage, isGenericError } = useSelector(
    (state) => state.dmaAps
  );
  const fetchData = useCallback(async () => {
    try {
      const [apsResponse, typesResponse] = await Promise.all([
        dispatch(fetchAps({ moduleId, token })).unwrap(),
        dispatch(fetchApsTypes({ token })).unwrap(),
      ]);
      if (typesResponse.success) {
        dispatchState({ type: "SET_TYPES", payload: typesResponse.data });
      }
      if (Array.isArray(apsResponse.data)) {
        const apsData = apsResponse.data.map((item) => ({
          ...item,
          typeName: item.actProServiceType?.name || "Unknown",
        }));
        dispatchState({ type: "SET_APS_DATA", payload: apsData });
      } else {
        dispatchState({ type: "SET_APS_DATA", payload: [] });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [dispatch, moduleId, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    isApsTableView,
    isApsModalOpen,
    modalApsMode,
    modalApsTitle,
    selectedApsRow,
    currentApsPage,
    searchApsQuery,
    isApsDeleteModalOpen,
    apsToDelete,
    types,
    apsData,
  } = state;

  const handleEdit = useCallback((row) => {
    dispatchState({
      type: "SET_MODAL",
      payload: {
        isOpen: true,
        mode: "edit",
        title: "Edit activity, product or services",
        row,
      },
    });
    dispatchState({
      type: "SET_APS_TO_DELETE",
      payload: row,
    });
  }, []);

  const handleDelete = async () => {
    if (!apsToDelete) return;

    try {
      const response = await dispatch(
        deleteAps({ apsId: apsToDelete.id, moduleId, token })
      ).unwrap();
      if (!response.error) {
        const updatedApsData = apsData.filter(
          (aps) => aps.id !== apsToDelete.id
        );
        dispatchState({
          type: "SET_APS_DATA",
          payload: updatedApsData,
        });
        dispatchState({
          type: "SET_DELETE_MODAL",
          payload: { isOpen: false, row: null },
        });
        dispatchState({
          type: "SET_MODAL",
          payload: {
            isOpen: false,
            row: null,
          },
        });
      } else {
        console.error("Error deleting APS:", response.error);
      }
    } catch (error) {
      console.error("Error deleting APS:", error);
    } finally {
      fetchData();
    }
  };

  const openDeleteModal = useCallback(() => {
    dispatchState({ type: "SET_IS_APS_DELETE_MODAL_OPEN", payload: true });
  }, []);

  const handleSourceValueClick = async (apsData) => {
    try {
      let response;
      if (modalApsMode === "add") {
        response = await dispatch(
          createAps({ moduleId, apsData, token })
        ).unwrap();
        if (response) {
          fetchData();
        } else {
          console.error("Error creating APS:", response.error);
        }
      } else if (modalApsMode === "edit" && selectedApsRow) {
        response = await dispatch(
          updateAps({ apsId: selectedApsRow.id, moduleId, apsData, token })
        ).unwrap();
        if (response) {
          fetchData();
        } else {
          console.error("Error updating APS:", response.error);
        }
      }
      dispatchState({
        type: "SET_MODAL",
        payload: { isOpen: false, mode: "add", title: "", row: null },
      });
    } catch (error) {
      console.error("Error creating/updating APS:", error);
    }
  };

  const renderApsTableCell = useCallback(
    (colName, value, row, index) => {
      if (colName === "Action") {
        return (
          <TableCell key="actions" sx={{ textAlign: "right", width: "100px" }}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={() => handleEdit(row)}>
                <Edit />
              </IconButton>
            </Box>
          </TableCell>
        );
      }
      if (colName === "actProServiceType") {
        return <TableCell key={index}>{row.actProServiceType.name}</TableCell>;
      }
      if (colName === "description") {
        return (
          <TableCell key={index}>
            <MotifTruncate expanded={true}>{value}</MotifTruncate>
          </TableCell>
        );
      }
      return <TableCell key={index}>{value}</TableCell>;
    },
    [handleEdit]
  );

  const generateRowKey = (row) => row.id;

  const handleOpenModal = (mode) => {
    dispatchState({
      type: "SET_MODAL",
      payload: {
        isOpen: true,
        mode,
        title:
          mode === "add"
            ? "New activity, product or services"
            : "Edit activity, product or services",
        row: null,
      },
    });
  };

  const handleCloseModal = () => {
    dispatchState({
      type: "SET_MODAL",
      payload: { isOpen: false, mode: "add", title: "", row: null },
    });
  };

  const handlePageChange = (page) => {
    dispatchState({ type: "SET_PAGE", payload: page });
  };

  const handleSearch = (event) => {
    dispatchState({ type: "SET_SEARCH_QUERY", payload: String(event) });
  };

  const getApsFilteredData = useMemo(() => {
    return apsData.filter((item) => {
      const searchString = searchApsQuery.toLowerCase();
      return (
        item.name.toLowerCase().includes(searchString) ||
        item.description.toLowerCase().includes(searchString) ||
        item.typeName.toLowerCase().includes(searchString)
      );
    });
  }, [apsData, searchApsQuery]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentApsPage - 1) * 10;
    return getApsFilteredData.slice(startIndex, startIndex + 10);
  }, [currentApsPage, getApsFilteredData]);

  const columnsAps = [
    { colName: "name", label: "Name", showSorting: true },
    { colName: "typeName", label: "Type", showSorting: true },
    {
      colName: "description",
      label: "Description",
      showSorting: true,
      width: "75em",
    },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  const totalPages = Math.ceil(getApsFilteredData.length / 10);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  // Whenever isError or errorMessage changes, reset showErrorModal
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleOpenModal("add")}
        onChangeSearchInput={handleSearch}
      />
      <TopBar
        isTableView={isApsTableView}
        totalResults={apsData.length}
        filteredResults={getApsFilteredData.length}
        onTableViewClick={() =>
          dispatchState({ type: "SET_VIEW", payload: true })
        }
        onCardViewClick={() =>
          dispatchState({ type: "SET_VIEW", payload: false })
        }
      />
      <div className="gl-card-table-switch">
        {isApsTableView ? (
          <ClientTable
            columns={columnsAps}
            data={getApsFilteredData}
            itemsPerPage={5}
            renderTableCell={renderApsTableCell}
            generateRowKey={generateRowKey}
          />
        ) : (
          <div className="card-container">
            {paginatedData.map((item) => (
              <CardDMS
                key={item.id}
                header={item.name}
                pageName="APS"
                stakeHolderType={item.actProServiceType.name}
                descriptionHeaderText={item.description}
                handleEdit={() => handleEdit(item)}
              />
            ))}
          </div>
        )}
      </div>
      {!isApsTableView && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentApsPage}
          onPageChange={handlePageChange}
        />
      )}
      {isApsModalOpen && (
        <SourceValue
          onClose={handleCloseModal}
          setName={modalApsTitle}
          handleStatusChange={() => {}}
          handleSourceValueClick={handleSourceValueClick}
          modalMode={modalApsMode}
          NameLabel={t("Name")}
          TypeLabel={t("Type")}
          InputName={t("EnterName")}
          SelectType={t("SelectType")}
          DescriptionLabel={t("Description")}
          cancelButtonLabel={t("Cancel")}
          saveButtonLabel={t("AddToList")}
          updateButtonLabel={t("Update")}
          isDisabled={isLoading}
          pageName="APS"
          types={types}
          selectedRow={selectedApsRow}
          onDeleteClick={openDeleteModal}
          DeleteLabel={t("DeleteLabel")}
        />
      )}
      {isApsDeleteModalOpen && (
        <DeleteModal
          isOpen={isApsDeleteModalOpen}
          onClose={() =>
            dispatchState({
              type: "SET_DELETE_MODAL",
              payload: { isOpen: false, row: null },
            })
          }
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={() =>
            dispatchState({
              type: "SET_DELETE_MODAL",
              payload: { isOpen: false, row: null },
            })
          }
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </div>
  );
};

export default Aps;
